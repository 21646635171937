<template>
  <q-dialog v-model="show" persistent class=" row flex flex-center">
    <q-card style="width: 300px;">
      <q-card-section class="text-center text-white text-bold bg-primary">
        Upload Bukti Pembayaran Pertama
      </q-card-section>
      <q-card-section>
        <label for="file">Pilih File</label>
        <div>
          <label for="file">
            <div class="browse-area">
              <div style="padding: 6px; font-weight: 100;">{{ selectFile }}</div>
              <div><q-icon class="q-pa-xs" color="light-blue-6" size="md" name="attach_file" /></div>
            </div>
          </label>
          <input type="file" id="file" ref="file" accept=".png, .jpg, .jpeg" @change="chgFile" style="display: none;" />
        </div>
      </q-card-section>
      <q-card-actions class="row q-pa-md justify-between">
        <q-btn v-bind="btnAtr" outline label="Batal" @click="$router.go(-1)" />
        <q-btn v-bind="btnAtr" label="Upload" @click="uploadFile" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import md5 from "md5";
let token = localStorage.getItem("smp-afbs");
export default {
  name: "Daftar",
  data: function() {
    return {
      btnAtr: {
        noCaps: true,
        style: "width: 110px",
        color: "primary",
      },
      user: {},
      model: null,
      isPwd: true,
      remember: false,
      show: true,
      selectFile: "",
    };
  },
  mounted() {
    if (!this.$store.state.currentUser) this.$router.push("/login");
  },
  methods: {
    chgFile() {
      if (this.$refs.file.files[0]) this.selectFile = this.$refs.file.files[0].name;
      else this.selectFile = "";
    },
    uploadFile() {
      this.theFile = this.$refs.file.files[0];
      if (this.theFile) {
        var d = new Date();
        let nama_file =
          this.$store.state.currentUser.id +
          "_pembayaran-1_" +
          d.getFullYear().toString() +
          (d.getMonth() + 1).toString() +
          d.getDate().toString() +
          "_" +
          this.theFile.name;
        var formData = new FormData();
        formData.append("file", this.theFile);
        formData.append("newname", nama_file);
        formData.append("id", this.$store.state.currentUser.id);

        this.$http
          .post("/upload.php", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((result) => {
            this.$http
              .put("/update_pembayaran_golongan/" + this.$store.state.currentUser.username + "/" + nama_file, {
                headers: { Authorization: "Bearer " + token },
              })
              .then((result) => {
                this.$q.notify({
                  icon: "done",
                  color: "positive",
                  message: "Bukti bayar terkirim",
                });

                this.$router.go(-1);
              });
          })
          .catch((err) => {
            this.$q.notify({
              icon: "warning",
              color: "negative",
              message: "Gagal upload file",
            });
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form {
  width: 300px;
}

input {
  width: 100%;
  border: 1px solid silver;
  border-radius: 3px;
  line-height: 28px;
  padding: 6px 0px;
  margin-bottom: 30px;
}
label {
  color: #606060;
}
.browse-area {
  width: 100%;
  line-height: 28px;
  margin: 0px;
  border: 1px solid silver;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
}
</style>
